import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { banner, singlePageContainer, container } from '../styles/single.module.scss'
import SectionTitle from './sectionTitle'

export default function DefaultPageLayout({ data }) {
  const { title, content, featuredImage } = data.contentfulPage

  return (
    <>
      <div className={banner}>
        <GatsbyImage image={featuredImage?.gatsbyImageData} alt={featuredImage.alt || `cover image`} objectFit="cover" />
      </div>
      <div className={singlePageContainer}>
        <SectionTitle text={title} />
      </div>
      <div
        className={container}
        dangerouslySetInnerHTML={{
          __html: content.childMarkdownRemark.html.replace(/\n/g, '<br />'),
        }}
      />
    </>
  )
}
