import React from 'react'

class HubspotRecruitForm extends React.Component {
  componentDidMount() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/shell.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '5823156',
          formId: '92d4c882-b9c5-4a4c-997c-0cc6b992c067',
          target: '#hubspotForm',
        })
      }
    })
  }

  render() {
    return <div id="hubspotForm" style={{ minHeight: '450px' }}></div>
  }
}

export default HubspotRecruitForm
