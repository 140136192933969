import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import DefaultPageLayout from '../components/defaultPageLayout'
import { container } from '../styles/single.module.scss'
import HubspotForm from '~/components/form/hubspot_for_recruit'

const RecruitPage = ({ data }) => {
  return (
    <Layout>
      <Seo title={data.contentfulPage.title} />
      <DefaultPageLayout data={data} />
      <div className={container + ' pt-10'}>
        <HubspotForm />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "recruit" }) {
      id
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      featuredImage {
        gatsbyImageData
      }
    }
  }
`

export default RecruitPage
